(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("uplot"), require("vue"));
	else if(typeof define === 'function' && define.amd)
		define(["uplot", "vue"], factory);
	else if(typeof exports === 'object')
		exports["UplotVue"] = factory(require("uplot"), require("vue"));
	else
		root["UplotVue"] = factory(root["uPlot"], root["Vue"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_uplot__, __WEBPACK_EXTERNAL_MODULE_vue__) {
return 